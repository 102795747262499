<template lang="html">
  <div>
    <div @click='langOn()' class='bttn'>
      <p>{{ $t("nav.language") }}</p><img src="../assets/language.png" alt="language" id='bt'>
    </div>
    <div class="languageWrapper">
      <ul class="LanguageMenu" v-if='langNav'>
        <li class='pl' @click='translate("pl")'>{{ $t("lang.pl") }}</li>
        <li class='eng' @click='translate("en")'>{{ $t("lang.en") }}</li>
        <li class='de' @click='translate("de")'>{{ $t("lang.de") }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langNav: false,
    }
  },
  methods: {
    langOn() {
      this.langNav = !this.langNav
    },
    translate(lang) {
      this.$i18n.locale = lang
      this.langOn()
    }
  }
}
</script>
<style lang="scss">
.languageWrapper {
  position: relative;

  @media screen and (max-width: 768px) {
    position: static;
  }
}

.bttn {
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  display: flex;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    p {
      display: none;
    }
  }

  img {
    width: 50px;
    height: 50px;
  }

  p {
    font-size: 2.4rem;
    user-select: none;
  }
}

.LanguageMenu {
  position: absolute;
  top: 50%;
  right: -5%;
  border: 3px solid #007CB0;
  padding: 1rem 4rem;
  margin-top: 2rem;
  border-radius: 5px;
  background: white;
  list-style: none;

  @media screen and (max-width: 768px) {
    width: fit-content;
    min-height: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 2rem 2rem 10rem;
    font-size: 5.4rem;
  }

  li:first-child,
  li:nth-child(2) {
    border-bottom: 2px solid rgba(0, 123, 176, 0.6);
    margin-bottom: 1.4rem;
    padding-bottom: 1rem;
  }

  .de {
    list-style-type: "🇩🇪";
  }

  .eng {
    list-style-type: "🇬🇧";
  }

  .pl {
    list-style-type: "🇵🇱";
  }

  li {
    cursor: pointer;
  }
}
</style>
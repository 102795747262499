<template lang="html">
  <div class='wrapper'>
    <div class="heroImg">
      <nav class='pcNav'>
        <div class="topNav">
          <img src="./assets/logoOrmet.svg" alt="Logo" class='logo'>
          <a href="#6" class="contact">
            <p>+48 518 202 433</p>
            <div class="bt">{{ $t("nav.contact") }}</div>
          </a>
        </div>
        <div class="bottomNav">
          <a href="#5">{{ $t("nav.products") }}</a>
          <a href="#latches1">{{ $t("nav.latches") }}</a>
          <a href="#latches2">{{ $t("nav.centers") }}</a>
          <LangTranslate />
        </div>
      </nav>
      <nav class='mobileNav'>
        <LangTranslate />
        <img src="./assets/logoOrmet.svg" alt="Logo" class='logo'>
        <button class='menu' @click="turnMenu()" :class="{ clicked: this.mobileNav }"
          aria-controls='primary-navigation'>
          <svg class='hamburger' viewBox='0 0 100 100' width='80'>
            <rect class='line l1' width='80' height='10' x='10' y='25' rx='5'></rect>
            <rect class='line l2' width='80' height='10' x='10' y='45' rx='5'></rect>
            <rect class='line l3' width='80' height='10' x='10' y='65' rx='5'></rect>
          </svg>
        </button>
        <div class="mobileView" v-if='mobileNav'>
          <a href="#2" @click="turnMenu()">{{ $t("history.header") }}</a>
          <a href="#3" @click="turnMenu()">{{ $t("nav.latches") }}</a>
          <a href="#latches1" @click="turnMenu()">{{ $t("nav.centers") }}</a>
          <a href="#latches2" @click="turnMenu()">{{ $t("nav.products") }}</a>
          <a href="#6" @click="turnMenu()">{{ $t("nav.contact") }}</a>
        </div>
      </nav>
      <article class="content">
        <h2>{{ $t("about.header") }}</h2>
        <p>{{ $t("about.content") }}</p>
        <a href="#5">{{ $t("about.button") }}</a>
      </article>
    </div>
    <main>
      <section id='2' class='ourStory'>
        <article>
          <h2>{{ $t("history.header") }}</h2>
          <p>{{ $t("history.content") }}
          </p>
          <a href="#6">{{ $t("nav.contact") }}</a>
        </article>
        <img src="./assets/pexels-cottonbro-studio-7479027.jpg" alt="Machine">
      </section>
      <section id='latches1' class='lathes' ref='gallery-wrapper'>
        <h2>{{ $t("nav.latches") }}</h2>
        <div class="scrollWrapper" ref='gallery'>
          <CncMachines v-for='machine in machines' :key='machine.name' :name='machine.name'
            :img='machine.img' :advantages='machine.advantages' />
        </div>
      </section>
      <section id="latches2" class='lathes' ref='gallery-wrapper2'>
        <h2>{{ $t("nav.centers") }}</h2>
        <div class="scrollWrapper" ref='gallery2'>
          <CncMachines v-for='center in centers' :key='center.name' :name='center.name'
            :img='center.img' :advantages='center.advantages' />
        </div>
      </section>
      <section id="5" class='products'>
        <h2>{{ $t("nav.products") }}</h2>
        <img src="./assets/product1.jpg" alt="Image of products" class='p1'>
        <img src="./assets/product2.jpg" alt="Image of products" class='p2'>
        <img src="./assets/product3.jpg" alt="Image of products" class='p3'>
        <img src="./assets/product4.jpg" alt="Image of products" class='p4'>
        <img src="./assets//product5.jpg" alt="Image of products" class='p5'>
      </section>
      <section id="6" class='contact'>
        <article>
          <h2>{{ $t("contactUs.header") }}</h2>
          <h3>"ORMET" Filip Orłowski</h3>
          <p><span class="material-symbols-outlined">
              location_on
            </span>{{ $t("contactUs.localization") }}</p>
          <ul>
            <li>
              ul. Cmentarna 86, 85-184 Bydgoszcz
            </li>
          </ul>
          <p><span class="material-symbols-outlined">
              mail
            </span>Mail</p>
          <ul>
            <li>
              info@ormet.eu
            </li>
          </ul>
          <p><span class="material-symbols-outlined">
              call
            </span>{{ $t("contactUs.phone") }}</p>
          <ul>
            <li>Filip Orłowski +48 518 202 433</li>
            <li>Janusz Orłowski +48 517 068 244</li>
          </ul>
          <p><span class="material-symbols-outlined">
              text_snippet
            </span>NIP: 9671443479 REGON: 388260890</p>
        </article>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2395.51717981179!2d18.013582177083883!3d53.10090569305925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47030dad8cc9a103%3A0x4938733a67fd5afb!2sOrmet!5e0!3m2!1spl!2spl!4v1688724296477!5m2!1spl!2spl"
          width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade" title='Google map'>
        </iframe>
      </section>
    </main>
    <footer>
      <p>{{ $t("nav.footer") }}</p>
    </footer>
  </div>
</template>
<script>
import LangTranslate from './components/langTranslate.vue';
import CncMachines from './components/CncMachines.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function stopScroll() {
  window.scrollTo(0, 0);
}
export default {
  components: { CncMachines, LangTranslate },
  data() {
    return {
      initialScrollLeft: 0,
      mobileNav: false,
    }
  },
  computed: {
    machines() {
      return [
        {
          name: "AVIA Turn 30M",
          img: require("@/assets/avia.jpg"),
          advantages: [
            "15 kW",
            this.$t("latches.avia30.one"),
            this.$t("latches.avia30.two"),
            this.$t("latches.avia30.three"),
          ]
        },
        {
          name: "Nakamura TW 20",
          img: require("@/assets/Nakamura20.jpg"),
          advantages: [
            this.$t("latches.nakamura20.one"),
            this.$t("latches.nakamura20.two"),
            this.$t("latches.nakamura20.three"),
            this.$t("latches.nakamura20.four"),
            this.$t("latches.nakamura20.five"),
            this.$t("latches.nakamura20.six"),
            this.$t("latches.nakamura20.seven"),
            this.$t("latches.nakamura20.eight"),
          ]
        },
        {
          name: "Takisawa EX-106",
          img: require("@/assets/Takisawa106.jpg"),
          advantages: [
            this.$t("latches.takisawa106.one"),
            this.$t("latches.takisawa106.two"),
          ]
        },
        {
          name: "Takisawa EX-110",
          img: require("@/assets/Takisawa110.jpg"),
          advantages: [
            this.$t("latches.takisawa110.one"),
            this.$t("latches.takisawa110.two"),
            this.$t("latches.takisawa110.three"),
            this.$t("latches.takisawa110.four"),
          ]
        },
        {
          name: "Mexpol TUB 40",
          img: require("@/assets/TUB40.jpg"),
          advantages: [
            this.$t("latches.mexpol40.one"),
            this.$t("latches.mexpol40.two"),
            this.$t("latches.mexpol40.three"),
            this.$t("latches.mexpol40.four"),
            this.$t("latches.mexpol40.five"),
          ]
        },
        {
          name: "Doosan Puma 2600Y",
          img: require("@/assets/Doosan.jpg"),
          advantages: [
            "22 kW",
            this.$t("latches.doosan.one"),
            this.$t("latches.doosan.two"),
            this.$t("latches.doosan.three"),
          ]
        },
        {
          name: "Pila Everising S-250 HA",
          img: require("@/assets/PilaS250.jpg"),
          advantages: [
            this.$t("latches.pilaS.one"),
            this.$t("latches.pilaS.two"),
          ]
        },
        {
          name: "Pila Berg & Schmid",
          img: require("@/assets/pilaBetg.jpg"),
          advantages: [
            this.$t("latches.pilaB.one"),
            this.$t("latches.pilaB.two"),
          ]
        }
      ]
    },
    centers() {
      return [
        {
          name: "AVIA VMC 1000",
          img: require("@/assets/Avia1000.jpg"),
          advantages: [
            this.$t("center.avia1000.1"),
            this.$t("center.avia1000.2"),
            this.$t("center.avia1000.3"),
            this.$t("center.avia1000.4"),
          ]
        },
        {
          name: "AVIA VMC 650",
          img: require("@/assets/Avia650.jpg"),
          advantages: [
            this.$t("center.avia650.1"),
            this.$t("center.avia650.2"),
            this.$t("center.avia650.3"),
            this.$t("center.avia650.4"),
            this.$t("center.avia650.5"),
          ]
        },
        {
          name: "LERINC V7",
          img: require("./assets/LerincV7.jpg"),
          advantages: [
            this.$t("center.lenric.1"),
            this.$t("center.lenric.2"),
            this.$t("center.lenric.3"),
            this.$t("center.lenric.4"),
          ]
        },
        {
          name: "AVIA VMC 800",
          img: require("@/assets/Avia800.jpg"),
          advantages: [
            this.$t("center.avia800.1"),
            this.$t("center.avia800.2"),
            this.$t("center.avia800.3"),
            this.$t("center.avia800.4"),
            this.$t("center.avia800.5"),
          ]
        }
      ]
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const gallery = this.$refs.gallery;

    let galleryWidth = gallery.offsetWidth;

    let ammountToScroll = -galleryWidth + window.innerWidth - 96;

    gsap.to(gallery, {
      x: ammountToScroll,
      ease: "none",
      scrollTrigger: {
        trigger: "#latches1",
        start: "top top",
        end: "bottom 5%",
        pin: true,
        scrub: true,
      }
    })

    const gallery2 = this.$refs.gallery2;

    let galleryWidth2 = gallery2.offsetWidth;

    let ammountToScroll2 = -galleryWidth2 + window.innerWidth - 96;

    gsap.to(gallery2, {
      x: ammountToScroll2,
      ease: "none",
      scrollTrigger: {
        trigger: "#latches2",
        start: "top top",
        end: "bottom 30px",
        pin: true,
        scrub: true,
      }
    })
  },
  methods: {
    turnMenu() {
      this.mobileNav = !this.mobileNav;

      if (this.mobileNav) {
        window.addEventListener('scroll', stopScroll);

      } else {
        window.removeEventListener('scroll', stopScroll);
      }
    },
  },
}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

  @media screen and (max-width: 768px) {
    font-size: 48.5%;
  }
}

body {
  background: #DDDDDD;
}


.wrapper {
  font-size: 2.4rem;

  h2 {
    font-size: 6rem;
    color: #007CB0;
  }
}

.heroImg {
  width: 90%;
  height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), no-repeat center / cover url("./assets/heroImg.jpg");
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 95% 100%, 0% 100%);
  transition: .3s all ease-in-out;

  @media screen and (max-width: 1268px) {
    width: 100%;
    clip-path: none;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.4)), no-repeat center / cover url("./assets/heroImg.jpg");
  }

  .content {
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 1rem;
    padding: 6rem 4rem;

    a {
      text-decoration: none;
      border: 2px solid black;
      border-radius: 5px;
      color: black;
      padding: .6em;
    }

    p {
      text-shadow: 0 4px 4px white;
      font-size: clamp(1rem, 1rem + 1.8vw, 3rem);
    }

    @media screen and (max-width: 1268px) {
      padding: 4rem 2rem;
    }

    @media screen and (max-width: 1024px) {
      font-size: 2rem;
      padding: 1rem 2rem;
    }

    @media screen and (max-width: 768px) {
      p {
        font-size: 2.6rem;
      }

      h2 {
        text-shadow: 10px 2px 10px white;
      }

      font-size: 2.8rem;
    }
  }
}

.pcNav {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  .topNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      .logo {
        width: 200px;
      }
    }
  }

  .bottomNav {
    display: flex;
    align-items: center;
    gap: 4rem;

    a {
      text-decoration: none;
      color: black;
    }
  }

  .logo {
    display: block;
    width: 300px;
    max-height: 100px;
    background: white;
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    color: black;

    &:focus {
      outline: none;

      .bt {
        outline: auto;
      }
    }

    .bt {
      margin-right: 2rem;
      border: 2px solid black;
      border-radius: 5px;
      padding: .6rem .8rem;
    }
  }
}

.mobileNav {
  display: none;
  height: 13%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    border-bottom: 2px solid #007CB0;
  }

  .logo {
    display: block;
    width: 200px;
    background: white;
  }

  button {
    width: fit-content;
    height: fit-content;
    border: none;
    background: none;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .menu {
    background: transparent;


    .line {
      transition:
        y 300ms ease-in 300ms,
        rotate 300ms ease-in,
        opacity 0ms 300ms;
      ;
      transform-origin: center;
    }
  }

  .clicked {
    .line {
      transition:
        y 300ms ease-in,
        rotate 300ms ease-in 300ms,
        opacity 0ms 300ms;
      ;
    }

    .l1,
    .l3 {
      y: 45%;
    }

    .l1 {
      rotate: 45deg;
    }

    .l2 {
      opacity: 0;
    }

    .l3 {
      rotate: -45deg;
    }
  }

  .mobileView {
    width: 100%;
    height: 87%;
    position: fixed;
    z-index: 5;
    background: rgba(255, 255, 255, 0.95);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    a {
      width: 75%;
      text-decoration: none;
      text-align: center;
      color: #007CB0;
      border: 2px solid #007CB0;
      padding: 2.8rem;
      border-radius: 5px;
    }
  }
}

section {
  width: 100%;
  min-height: 100vh;
  min-height: 100svh;
  padding-left: 4rem;

  @media screen and (max-width: 768px) {
    padding: 4rem 0 0 4rem;
  }
}

.ourStory {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: 40%;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%);
    align-self: self-end;
  }

  article {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    a {
      text-decoration: none;
      border: 2px solid black;
      border-radius: 5px;
      color: black;
      padding: .6em;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding-left: 0;
    gap: 0;

    article {
      padding-left: 4rem;

      p {
        font-size: 3rem;
        margin-right: 2rem;
      }
    }

    img {
      clip-path: polygon(0% 50%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  @media screen and (max-width: 400px) {
    grid-template-rows: 2fr 1fr;

    img {
      clip-path: none;
    }
  }
}

.lathes {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  h2 {
    padding-bottom: 2rem;
    white-space: nowrap;

    @media screen and (max-width: 1248px) {
      font-size: 5.8rem;
    }

    @media screen and (max-width: 1024px) {
      font-size: 4.6rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 3.2rem;
    }
  }

  /* .scrollWrapper {
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
  } */


}

.scrollWrapper {
  display: flex;
  gap: 2rem;
  padding: 2rem;

  /* display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  padding: 2rem;
  width: 100%;

  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
  }

  @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    justify-content: center;
  }
  */
}

.products {
  height: 100vh;
  padding: 4rem;
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem 6rem;
  grid-template-areas:
    "header p2 p2"
    "p1 p2 p2"
    "p3 p4 p5"
  ;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      "header header"
      "p1 p2"
      "p3 p4"
      "p5 p5"
    ;
  }

  @media screen and (max-width: 768px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "header header"
      "p1 p2"
      "p3 p2"
      "p4 p4"
      "p4 p4"
      "p5 p5"
      "p5 p5"
    ;
  }

  img {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  h2 {
    grid-area: header;
  }

  .p1 {
    grid-area: p1;
  }

  .p2 {
    grid-area: p2;
  }

  .p3 {
    grid-area: p3;
  }

  .p4 {
    grid-area: p4;
  }

  .p5 {
    grid-area: p5;
  }
}

.contact {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "article map";
  gap: 2rem;

  @media screen and (max-width: 1248px) {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "article"
      "map"
    ;
  }

  article {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 4rem;
    grid-area: article;
  }

  h3 {
    padding-bottom: 2rem;

    @media screen and (max-width: 768px) {
      font-size: 2.4rem;
    }
  }

  ul {
    margin-left: 8rem;

    @media screen and (max-width: 768px) {
      margin-left: 4rem;
      font-size: 1.8rem;
    }
  }

  p {
    margin: 1.4rem 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  iframe {
    margin-right: 2rem;
    border-radius: 10px;
    grid-area: map;

    @media screen and (max-width: 1600px) {
      width: 400px;
      height: 600px;
    }

    @media screen and (max-width: 1248px) {
      width: 400px;
      height: 400px;
      justify-self: center;
      margin-bottom: 4rem;
    }

    @media screen and (max-width: 768px) {
      width: 300px;
      height: 300px;
    }
  }

  .material-symbols-outlined {
    font-size: inherit;
  }
}

footer {
  font-size: 1.8rem;
  width: 100%;
  background: white;
  padding: 1em;
  text-align: center;
}
</style>
export default {
  "about": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz nasze produkty"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy specjalistami w produkcji części dla firm zajmujących się hydrauliką siłową, sterowaniem hydraulicznym, zaworami oraz w dziedzinie mechaniki precyzyjnej i budowy maszyn. Dysponujemy nowoczesnym parkiem maszynowym oraz wysokiej jakości narzędziami mocującymi i przyrządami pomiarowymi, co umożliwia szybkie i precyzyjne realizowanie zamówień. Naszym najważniejszym celem jest zapewnienie klientom wysokiej jakości produktów i terminowość dostaw, co przekłada się na ich pełne zadowolenie."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])}
  },
  "center": {
    "avia650": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc wrzeciona 7,5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obroty max. 10 000 Obr./min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chłodzenie przez wrzeciono o ciśnieniu 20 bar"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejazd stołu: X=650 Y=550 wrzeciona Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum obróbcze wyposażone w 24 narzędzia umożliwiające frezowanie, wiercenie i gwintowanie."])}
    },
    "avia800": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc wrzeciona 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obroty max. 10 000 Obr./min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chłodzenie poprzez wrzeciono o ciśnieniu 20 barów."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejazd stołu: X=800 Y=550 wrzeciona Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum obróbcze wyposażone w 24 narzędzia umożliwiające frezowanie, wiercenie i gwintowanie."])}
    },
    "avia1000": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc wrzeciona 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obroty max. 10 000 Obr./min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejazd stołu: X=1000 Y=550 wrzeciona Z=650 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc i sztywność obrabiarki umożliwiają szybkie i dokładne frezowanie, wiercenie (z chłodzeniem przez wrzeciono o ciśnieniu 20 bar) oraz gwintowanie obrabianych części. Magazyn z 24 narzędziami pozwala na wykonanie skomplikowanych części."])}
    },
    "lenric": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc wrzeciona 7,5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obroty max. 8000 Obr./min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejazd stołu: X=850 Y=500 wrzeciona Z=500 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum obróbcze wyposażone w 24 narzędzia umożliwiające frezowanie, wiercenie i gwintowanie."])}
    }
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontaktuj się"])},
  "contactUs": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  },
  "history": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma \"ORLOWSKI-CNC OBRÓBKA SKRAWANIEM\" powstała w 2005 roku w Niemczech. Od 2021 roku działamy jako \"ORMET\" w Bydgoszczy. Specjalizujemy się w wykonywaniu części toczonych i frezowanych na obrabiarkach CNC, zgodnie z zamówieniami i rysunkami technicznymi klienta. "])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasza historia"])}
  },
  "lang": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemiecki"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angielski"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])}
  },
  "latches": {
    "avia30": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. śred. toczenia = 300 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oś „C“ + Narzędzia z własnym napędem dają możliwość frezowania, wiercenia, gwintowania powierzchni bocznej i czołowej obrabianego detalu, oraz toczenia z pręta o średnicy do 65 mm."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dł. toczenia = 380 mm"])}
    },
    "doosan": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. śred. toczenia = 376 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oś „C i Y“ + Narzędzia z własnym napędem, dostępne w tokarce, umożliwiają frezowanie, wiercenie oraz gwintowanie powierzchni bocznej i czołowej obrabianego detalu."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dl. toczenia = 760 mm"])}
    },
    "mexpol40": {
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odczyt cyfrowy"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podtrzymka"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokarka konwencionalna"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dlug. Tocz. 1000 mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max śred. tocz. Ø 300 mm"])}
    },
    "nakamura20": {
      "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maszyna służy do wykonywania skomplikowanych części toczonych, które jednocześnie poddawane są frezowaniu, wierceniu, gwintowaniu oraz obróbce drugiej strony detalu po jego odcięciu."])},
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 x 12 narzedzi"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 wrzeciona"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 sterowanych osi"])},
      "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazyn do pretow"])},
      "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 x 6 narzedzi z wl. napedem"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Rewolwery narzedziowe"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. średnica pręta 51 mm"])}
    },
    "pilaB": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piła automatyczna"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. śred. preta 400 mm"])}
    },
    "pilaS": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piła automatyczna"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. śred. preta 250 mm"])}
    },
    "takisawa106": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokarka dwuosiowa do prostych części toczonych z pręta"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. średnica toczenia = ø 41"])}
    },
    "takisawa110": {
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokarka służy do toczenia wałków maszynowych, kołnierzy oraz tulei."])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 KW – 2 biegi"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dt. tocz. 700 mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. średnica tocz. 300 mm"])}
    }
  },
  "nav": {
    "centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centra obróbcze"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontaktuj się"])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © www.ormet.eu - \"ORMET\" Filip Orłowski Bydgoszcz wykonanie: Paweł Gołata"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
    "latches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokarki"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkty"])}
  }
}
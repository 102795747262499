export default {
  "about": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View our products"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are specialists in the production of parts for companies involved in power hydraulics, hydraulic controls, valves, and in the field of precision mechanics and mechanical engineering. We have a modern machine park and high-quality fastening tools and measuring instruments, which enables us to fulfill orders quickly and precisely. Our most important goal is to provide customers with high quality products and on-time delivery, which leads to their complete satisfaction."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
  },
  "center": {
    "avia650": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindle power 7.5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation max. 10,000 rpm"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 bar spindle cooling"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table travel: X=650 Y=550 spindle Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machining center equipped with 24 tools for milling, drilling and threading."])}
    },
    "avia800": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindle power 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation max. 10,000 rpm"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooling through a 20 bar spindle."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table travel: X=800 Y=550 spindle Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machining center equipped with 24 tools for milling, drilling and threading."])}
    },
    "avia1000": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindle power 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation max. 10,000 rpm"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table travel: X=1000 Y=550 spindle Z=650 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The machine tool's power and rigidity allow fast and accurate milling, drilling (with 20 bar spindle cooling) and threading of workpieces. A magazine with 24 tools allows the machine to make complex parts."])}
    },
    "lenric": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindle power 7.5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotation max. 8000 rpm"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table travel: X=850 Y=500 spindle Z=500 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machining center equipped with 24 tools for milling, drilling and threading."])}
    }
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us!"])},
  "contactUs": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "history": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company \"ORLOWSKI-CNC MACHINING\" was established in 2005 in Germany. Since 2021, we have been operating as \"ORMET\" in Bydgoszcz. We specialize in making turned and milled parts on CNC machine tools, according to customer orders and technical drawings."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our story"])}
  },
  "lang": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])}
  },
  "latches": {
    "avia30": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. diameter. turning = 300 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"C\" axis + Powered tools enable milling, drilling, threading of the side and face surfaces of the workpiece, and turning from a bar with a diameter of up to 65 mm."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. length . turning = 380 mm"])}
    },
    "doosan": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dia. turning = 376 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"C and Y\" axis + Self-powered tools, available on the lathe, enable milling, drilling and threading of the side and face of the workpiece."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dl. turning = 760 mm"])}
    },
    "mexpol40": {
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digital readout"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prop"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conventional lathe"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. debt. Roll. 1000mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Wed. roll Ø 300mm"])}
    },
    "nakamura20": {
      "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The machine is used to make complex turned parts, which are simultaneously milled, drilled, threaded and machined on the other side of the detail after it has been cut off."])},
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 x 12 tools"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 spindles"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 controlled axes"])},
      "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretzel warehouse"])},
      "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 x 6 tools incl. drive"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Tool revolvers"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. rod diameter 51 mm"])}
    },
    "pilaB": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic saw"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. dia. bar 400 mm"])}
    },
    "pilaS": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic saw"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. dia. bar 250 mm"])}
    },
    "takisawa106": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two axis lathe for simple bar turned parts"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. turning diameter = ø 41"])}
    },
    "takisawa110": {
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lathe is used for turning machine shafts, flanges and sleeves."])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 KW - 2 gears"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. dt. roll 700mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. rolling diameter 300mm"])}
    }
  },
  "nav": {
    "centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machining centers"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © www.ormet.eu - \"ORMET\" Filip Orłowski Bydgoszcz created by: Paweł Gołata"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "latches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lathes"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])}
  }
}
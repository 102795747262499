export default {
  "about": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Produkte ansehen"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als zuverlässiger Zulieferer mit der Kernkompetenz in der CNC-Fräs- und Drehtechnik haben wir uns im Laufe der Jahre einen modernen Maschinenpark aufgebaut: Hier können vielfältige \nBearbeitungsaufgaben durch effektive Produktionsprozesse ausgeführt werden. Unsere Kunden kommen aus den Bereichen Maschinenbau, Hydraulik- und Ventiltechnik, Apparate- und Anlagenbau sowie Feinmechanik. Gerade in diesen Branchen ist ein Höchstmaß an Qualität eine unverzichtbare Voraussetzung. Eine Voraussetzung, deren Erfüllung unser dynamisches Team zu 100 % garantiert. "])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])}
  },
  "center": {
    "avia650": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindelleistung 7,5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S = max. 10.000 U/min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 bar Spindelkühlung"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tischweg: X=650 Y=550 Spindel Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungszentrum ausgestattet mit 24 Werkzeugen zum Fräsen, Bohren und Gewindeschneiden."])}
    },
    "avia800": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindelleistung 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S = max. 10.000 U/min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kühlung durch eine 20-bar-Spindel."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tischweg: X=800 Y=550 Spindel Z=650 mm"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungszentrum ausgestattet mit 24 Werkzeugen zum Fräsen, Bohren und Gewindeschneiden."])}
    },
    "avia1000": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindelleistung 10 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S = max. 10.000 U/min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tischweg: X=1000 Y=550 Spindel Z=650 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Leistung und Steifigkeit der Werkzeugmaschine ermöglicht schnelles und präzises Fräsen, Bohren (mit 20 bar Spindelkühlung) und Gewindeschneiden von Werkstücken. Ein Werkzeugmagazin mit 24 Werkzeugen ermöglicht die Herstellung komplexer Teile."])}
    },
    "lenric": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spindelleistung 7,5 KW"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S = max. 8.000 U/min"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tischweg: X=850 Y=500 Spindel Z=500 mm"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungszentrum ausgestattet mit 24 Werkzeugen zum Fräsen, Bohren und Gewindeschneiden."])}
    }
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt aufnehmen"])},
  "contactUs": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  },
  "history": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Familienunternehmen Orlowski CNC-Zerspanungstechnik wurde im Jahr 2005 von J. Orlowski in Mettmann gegründet. Seit 2021 sind wir als Firma ORMET in Bydgoszcz in Polen tätig."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Geschichte"])}
  },
  "lang": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsche"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnisch"])}
  },
  "latches": {
    "avia30": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehdurchmesser 300 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„C“-Achse + Angetriebene Werkzeuge ermöglichen das Fräsen, Bohren, Gewindeschneiden der Seiten- und Stirnflächen des Werkstücks sowie das Drehen aus einer Stange mit einem Durchmesser von bis zu 65 mm."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehlänge 380min"])}
    },
    "doosan": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehdurchmesser 376 mm"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bohren und Gewindeschneiden der Seiten- und Stirnfläche des Werkstücks."])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehlänge 760mm"])}
    },
    "mexpol40": {
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digitale Anzeige"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reitstock und Lünette"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konventionelle Drehmaschine"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehlänge 1000mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehdurchmesser 300mm"])}
    },
    "nakamura20": {
      "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Maschine werden komplexe Drehteile hergestellt, die nach dem Abtrennen des Teils gleichzeitig gefräst, gebohrt, mit Gewinde versehen und auf der anderen Seite bearbeitet werden."])},
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175/175 mm X-Achse"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Spindel"])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 gesteuerte Achsen"])},
      "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x12 Werkzeuge"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Revolver"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51 mm Stangendurchlaß"])}
    },
    "pilaB": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Säge"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Durchm. Stange 400 mm"])}
    },
    "pilaS": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Säge"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Durchm. Stange 250 mm"])}
    },
    "takisawa106": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiachsige Drehmaschine für einfache Stangendrehteile"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehdurchmesser = ø 41"])}
    },
    "takisawa110": {
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Drehmaschine dient zum Drehen von Maschinenwellen, Flanschen und Hülsen."])},
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 KW - 2 Gänge"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehlänge 700mm"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. Drehdurchmesser 300mm"])}
    }
  },
  "nav": {
    "centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungszentren"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt aufnehmen"])},
    "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © www.ormet.eu - „ORMET“ Filip Orłowski Bydgoszcz erstellt von: Paweł Gołata"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "latches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehmaschinen"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])}
  }
}
<template lang="html">
  <div class="machine">
    <img :src="img" :alt="name + ' machine photo'">
    <p>{{ name }}</p>
    <hr />
    <ul>
      <li v-for='advantage in advantages' v-bind:key='advantage'>
        {{ advantage }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    img: String,
    advantages: Array,
  }
}
</script>
<style lang="scss">
.machine {
  border: 3px solid black;
  width: 30rem;
  height: 44rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 44rem;
    width: 34rem;
  }

  img {
    display: block;
    width: 100%;
    height: 40%;
  }

  hr {
    width: 80%;
  }

  p {
    text-align: center;
    font-size: 2.2rem;
    margin: 1rem 0;
    font-weight: bold;
  }

  ul {
    padding: 15px 45px;

    li {
      font-size: 1.2rem;
      padding: 0.2rem;
    }
  }
}
</style>